import { FC, HTMLAttributes } from 'react';
import styles from './styles.module.scss';
import { ReactComponent as StarsSVG } from '../../assets/images/stars.svg';
import { RxCrossCircled } from 'react-icons/rx';
import Button, { BUTTON_TYPE } from '../Button/Button';
import { getStringByLanguage } from '../../utils/utils';
import {
  NOTIFICATION_STATUS,
  updateNotificationStatus,
  NotificationInfo,
} from '../../apis/notifications';
import { formatTime } from '../../utils/utils';

import cn from 'classnames';
interface FuncGroup extends HTMLAttributes<HTMLDivElement> {
  data: NotificationInfo;
}
const NotificationItem: FC<FuncGroup> = ({ data }) => {
  const handleButtons = async ({
    status,
    value,
  }: {
    status: keyof typeof NOTIFICATION_STATUS;
    value: boolean;
  }) => {
    await updateNotificationStatus({
      data,
      status,
      value,
    });
  };
  return (
    <div className={styles['ni-body']}>
      <div className={styles['ni-content']}>
        <div className={styles['ni-content-recommend']}>
          <StarsSVG className={cn([styles['ni-content-icon']])} />
          <div className={cn([styles['ni-content-body']])}>
            <p className={cn([styles['ni-content-text']])}>AI recommendation</p>
            <div className={cn([styles['ni-content-title']])}>
              <span className={styles['ni-content-prefix']}>
                The sun will shine
              </span>{' '}
              from {formatTime(data.notification_start_at.toString())}-
              {formatTime(data.notification_end_at.toString())}
            </div>
          </div>
        </div>
        <RxCrossCircled className={cn([styles['ni-content-close']])} />
      </div>
      <div className={styles['ni-content-footer']}>
        <Button
          className={cn([styles['ni-footer-ok']])}
          type={BUTTON_TYPE.PRIMARY}
          onClick={() =>
            handleButtons({
              status: 'FOLLOWED',
              value: true,
            })
          }
        >
          {getStringByLanguage('FOLLOW_RECOMMENDATION')}
        </Button>
        <Button
          className={cn([styles['ni-footer-ok']])}
          type={BUTTON_TYPE.SECONDARY}
          onClick={() =>
            handleButtons({
              status: 'FOLLOWED',
              value: false,
            })
          }
          outline
        >
          {getStringByLanguage('DONT_FOLLOW_RECOMMENDATION')}
        </Button>
      </div>
    </div>
  );
};

export default NotificationItem;

import Geocode from 'react-geocode';
import * as DateFNS from 'date-fns';
import { enUS, es, fr, nl } from 'date-fns/locale';
import axios from 'axios';

import {
  API_BASE_URL,
  API_GOOGLE_KEY,
  API_WEATHER_KEY,
  API_WEATHER_URL,
  GOOGLE_TRANSLATE_API_KEY,
  _global,
} from '../config/config';
import {
  LanguageKeyProps,
  LanguageSrcProps,
  languageKeyProps,
  languageSrcProps,
  strings,
} from './constants';
import { GPSLocation } from '../services/reducers/sharedReducer';

import variables from '../assets/sass/_variables.module.scss';
import { TimeRange } from '../apis/streams';

export interface AnyObject {
  [key: string]:
    | AnyObject
    | AnyObject[]
    | string
    | number
    | boolean
    | undefined
    | null;
}

export const languageLocales = {
  en: enUS,
  fr: fr,
  es: es,
  nl: nl,
};

export const isAvaliableKey = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  key: any,
): key is LanguageKeyProps => languageKeyProps.includes(key);

export const isAvaliableLanguage = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  language: any,
): language is LanguageSrcProps => languageSrcProps.includes(language);

export const getAvaliableLanguage = (): LanguageSrcProps => {
  const language = localStorage.getItem('lang');
  if (language === null) {
    localStorage.setItem('lang', 'en');
    return 'en';
  }
  if (isAvaliableLanguage(language)) {
    return language;
  }
  return 'en';
};

export const isParticipantView = () => {
  return localStorage.getItem('impersonate');
};

export const showSwitchLabel = () => {
  return !localStorage.getItem('impersonate')
    ? 'Switch to Participant View'
    : 'Switch to Manager View';
};

/**
 * If date is in between biggerThan and smallerThan return true.
 * Otherwise return false.
 * @param date Date want to compare.
 * @param biggerThan Date left compare.
 * @param smallerThan Date right compare.
 */
export const dateBetween = (
  date: Date | string | number,
  biggerThan: Date | string | number,
  smallerThan: Date | string | number,
) => {
  date = date2Number(date);
  biggerThan = date2Number(biggerThan);
  smallerThan = date2Number(smallerThan);
  if (date >= biggerThan && date <= smallerThan) {
    return true;
  }
  return false;
};

export const date2Number = (date: Date | string | number) => {
  return new Date(date).getTime();
};

export const colorDict = variables;

Geocode.setApiKey(API_GOOGLE_KEY);
export interface GeocodeLocation {
  lat: number;
  lng: number;
}

export interface GeocodeAddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface GeocodePlusCode {
  compound_code: string;
  global_code: string;
}

export interface GeocodeResult {
  address_components: GeocodeAddressComponent[];
  formatted_address: string;
  geometry: {
    location: GeocodeLocation;
    location_type: string;
    viewport: {
      northeast: GeocodeLocation;
      southwest: GeocodeLocation;
    };
  };
  place_id: string;
  plus_code?: GeocodePlusCode;
  types: string[];
}

export interface GeocodeResponse {
  plus_code: GeocodePlusCode;
  results: GeocodeResult[];
  status: string;
}

export interface LocationInfo {
  street_number?: string;
  street_name?: string;
  city?: string;
  state?: string;
  postcode?: string;
  country?: string;
}

export const getLocationKey = (
  types: string[],
): keyof LocationInfo | undefined => {
  for (let i = 0; i < types.length; i++) {
    switch (types[i]) {
      case 'street_number':
        return 'street_number';
      case 'route':
        return 'street_name';
      case 'locality':
        return 'city';
      case 'administrative_area_level_1':
        return 'state';
      case 'postal_code':
        return 'postcode';
      case 'country':
        return 'country';
    }
  }
};

export const getLocationName = async (
  { latitude, longitude }: GPSLocation,
  los = false,
) => {
  Geocode.setLanguage(getAvaliableLanguage());
  const { results }: GeocodeResponse = await Geocode.fromLatLng(
    `${latitude}`,
    `${longitude}`,
  );

  const locationInfo: LocationInfo = {};

  for (let i = 0; i < results.length; i++) {
    const { types, address_components } = results[i];
    const key = getLocationKey(types);
    if (key) {
      for (let j = 0; j < address_components.length; j++) {
        const addrComp = address_components[j];
        const key = getLocationKey(addrComp.types);
        if (key) {
          locationInfo[key] = los ? addrComp.long_name : addrComp.short_name;
        }
      }
    }
  }

  return locationInfo;
};

export const getWeatherInfo = async ({ latitude, longitude }: GPSLocation) => {
  return await fetch(
    `${API_WEATHER_URL}/weather/?lat=${latitude}&lon=${longitude}&units=metric&APPID=${API_WEATHER_KEY}`,
  ).then((res) => res.json());
};

export const getLocation = () => {
  return new Promise<GeolocationPosition>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => resolve(position),
      (error) => reject(error),
    );
  });
};

export const getDocumentUrl = ({
  community_id,
  participant_id,
  id,
}: {
  community_id?: string | number | null;
  participant_id?: string | number | null;
  id?: string | number | null;
}) => {
  community_id = community_id || _global.user?.participant?.community_id;
  participant_id = participant_id || _global.user?.participant?.id;
  return `${API_BASE_URL}/community/${community_id}/participant/${participant_id}/document/${id}/download`;
};

export const getServerImageUrl = (url = '') => {
  return `${API_BASE_URL}/${url}`;
};

export const getStringByLanguage = (
  ...texts: LanguageKeyProps[] | LanguageKeyProps[][]
) => {
  if (texts.length === 0) {
    return '';
  }

  const language = getAvaliableLanguage();

  return texts
    .map((v) => (!Array.isArray(v) ? [v] : v))
    .flat()
    .map((text) => strings[language][text] || strings['en'][text] || text)
    .filter((text) => text.length)
    .join(' ');
};

export const getJSONOrString = (str: string | object) => {
  if (typeof str === 'object') {
    return str;
  }
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
};

export const delayAction: (
  interval: number,
  callback?: () => Promise<void>,
) => Promise<void> = (interval, callback) => {
  return new Promise((resolve) => {
    setTimeout(async () => {
      callback && (await callback());
      resolve();
    }, interval);
  });
};

export const isNewDocument = (created_at: string) => {
  const cDate = new Date();
  const sDate = DateFNS.startOfDay(cDate);

  const createdAt = new Date(created_at);

  return DateFNS.isAfter(createdAt, sDate);
};

export const getTagNames = (tags?: string | null): string[] => {
  if (!tags) {
    return [];
  }

  try {
    const names = JSON.parse(tags);
    if (Array.isArray(names)) {
      const matched = names.filter((name) => typeof name === 'string');
      return matched;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const getDate = (range: TimeRange, current: Date, step: 1 | -1) => {
  switch (range) {
    case TimeRange.Today:
      return DateFNS.addDays(current, step);
    case TimeRange.Week:
      return DateFNS.addWeeks(current, step);
    case TimeRange.Month:
      return DateFNS.addMonths(current, step);
    case TimeRange.Year:
      return DateFNS.addYears(current, step);
    default:
      return DateFNS.addDays(current, step);
  }
};

export const prepareLabels = (range: TimeRange, sDate = new Date()) => {
  const staticLabels: number[] = [];
  const startEnd: {
    start: number;
    end: number;
    interval: Duration;
  } = {
    start: 0,
    end: 0,
    interval: {},
  };
  switch (range) {
    case TimeRange.Today:
      startEnd.start = DateFNS.startOfDay(sDate).getTime();
      startEnd.end = DateFNS.endOfDay(sDate).getTime();
      startEnd.interval = {
        hours: 1,
      };
      break;
    case TimeRange.Week:
      startEnd.start = DateFNS.startOfWeek(sDate).getTime();
      startEnd.end = DateFNS.endOfWeek(sDate).getTime();
      startEnd.interval = {
        days: 1,
      };
      break;
    case TimeRange.Month:
      startEnd.start = DateFNS.startOfMonth(sDate).getTime();
      startEnd.end = DateFNS.endOfMonth(sDate).getTime();
      startEnd.interval = {
        days: 1,
      };
      break;
    case TimeRange.Year:
      startEnd.start = DateFNS.startOfYear(sDate).getTime();
      startEnd.end = DateFNS.endOfYear(sDate).getTime();
      startEnd.interval = {
        months: 1,
      };
      break;
    default:
      return [];
  }

  staticLabels.push(startEnd.start);

  while (staticLabels[staticLabels.length - 1] < startEnd.end) {
    const stamp = DateFNS.add(
      staticLabels[staticLabels.length - 1],
      startEnd.interval,
    ).getTime();

    if (stamp > startEnd.end) {
      break;
    }
    staticLabels.push(stamp);
  }

  if (range === TimeRange.Today) {
    staticLabels.push(
      DateFNS.add(
        staticLabels[staticLabels.length - 1],
        startEnd.interval,
      ).getTime(),
    );
  }

  return staticLabels;
};

export const getFlexableStamp = (timestamp: number, xAxisLabel: number[]) => {
  for (let i = xAxisLabel.length - 1; i >= 0; i--) {
    if (xAxisLabel[i] <= timestamp) {
      return xAxisLabel[i];
    }
  }

  return -1;
};

export const PRICES = {
  PRICE_PARTICIPANT: {
    price_id: process.env.REACT_APP_STRIPE_PRICE_PARTICIPANT,
    label: getStringByLanguage('PARTICIPANT'),
  },
  PRICE_COMMUNITY_MANAGER: {
    price_id: process.env.REACT_APP_STRIPE_PRICE_COMMUNITY_MANAGER,
    label: getStringByLanguage('COMMUNITY_MANAGER'),
  },
  PRICE_COMMUNITY_MANAGER_PRO: {
    price_id: process.env.REACT_APP_STRIPE_PRICE_COMMUNITY_MANAGER_PRO,
    label: getStringByLanguage('COMMUNITY_MANAGER', 'PRO'),
  },
};

export const value2KWH = ({
  value,
  unit,
  ...props
}: {
  value: number;
  unit: string;
}): { value: number; unit: string } => {
  const uKWH = getStringByLanguage('KWH');
  const uMWH = getStringByLanguage('MWH');
  const uGWH = getStringByLanguage('GWH');

  const unitDict = {
    [uKWH]: 1,
    [uMWH]: 1000,
    [uGWH]: 1000000,
  };

  if (!unitDict[unit]) {
    throw new Error('Incorrect unit');
  }

  value = value * unitDict[unit];
  value = numUtils.decimal(
    value,
    value > 100 ? 0 : value > 10 ? 1 : value > 1 ? 2 : 3,
  );

  return { value, unit, ...props };
};

export const value2FlexibleRounded = (value: number, decimal?: number) => {
  return numUtils.decimal(
    value,
    decimal !== undefined
      ? decimal
      : value > 100
      ? 0
      : value > 10
      ? 1
      : value > 1
      ? 2
      : 3,
  );
};

export const value2FlexibleUnit = (
  {
    value,
    unit,
    ...props
  }: {
    value: number;
    unit: string;
  },
  decimal?: number,
): { value: number; unit: string } => {
  const uKWH = getStringByLanguage('KWH');
  const uMWH = getStringByLanguage('MWH');
  const uGWH = getStringByLanguage('GWH');

  const upgradeDict = {
    [uKWH]: uMWH,
    [uMWH]: uGWH,
  };

  if (unit === uKWH) {
    value = value2FlexibleRounded(value, decimal);
  }

  if (value >= 1000) {
    if (!upgradeDict[unit]) {
      value = value2FlexibleRounded(value, decimal);
      return { value, unit, ...props };
    }
    value /= 1000;
    unit = upgradeDict[unit];
  }

  if (value >= 1000) {
    return value2FlexibleUnit({ value, unit, ...props }, decimal);
  }

  value = value2FlexibleRounded(value, decimal);

  return { value, unit, ...props };
};

const decimal = (value: number, decimal = 0) => {
  const digit = Math.pow(10, decimal);
  return Math.round(value * digit) / digit;
};

const isNumericString = (str: string) => {
  return !isNaN(parseFloat(str));
};

const isIntegerString = (str: string) => {
  return !isNaN(parseInt(str));
};

export const numUtils = {
  decimal,
  isNumericString,
  isIntegerString,
};

export const getTranslatedText = async (text: string): Promise<string> => {
  if (getAvaliableLanguage() === 'en') {
    return text;
  }
  const options = {
    method: 'POST',
    url: 'https://google-translator9.p.rapidapi.com/v2',
    headers: {
      'x-rapidapi-key': '8ab43cd305msha62cf3847465429p107a9fjsn87fd11552bc1',
      'x-rapidapi-host': 'google-translator9.p.rapidapi.com',
      'Content-Type': 'application/json',
    },
    data: {
      q: text,
      source: 'en',
      target: getAvaliableLanguage(),
      format: 'text',
    },
  };

  try {
    const response = await axios.request(options);
    return response.data.data.translations[0].translatedText;
  } catch (error) {
    console.error(error);
    throw new Error('Failed to translate text');
  }
};
const TRANSLATE_URL = `https://translation.googleapis.com/language/translate/v2?key=${GOOGLE_TRANSLATE_API_KEY}`;

export const googleTranslate = async (text: string): Promise<string> => {
  console.log(TRANSLATE_URL);
  const targetLanguage = getAvaliableLanguage(); // Ensure this function exists and returns a valid language code
  const requestBody = {
    q: text,
    target: targetLanguage,
    format: 'text',
  };

  try {
    const response = await axios.post(TRANSLATE_URL, requestBody);
    console.log(response.data.data.translations[0].translatedText);
    return response.data.data.translations[0].translatedText;
  } catch (error) {
    console.error(error);
    throw new Error('Failed to translate text');
  }
};

export const formatDate = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  return `${date.toLocaleDateString('en-US', options)}`;
};

export const formatTime = (dateString: string): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit', // Correct type
    minute: '2-digit', // Correct type
    hour12: false, // Set to true for 12-hour format with AM/PM
    timeZone: 'UTC',
  };
  return date.toLocaleTimeString('en-US', options);
};

import { FC, useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
// import { createRoot, Root } from 'react-dom/client';
import { BsInfoCircle } from 'react-icons/bs';
import { AiOutlineEuroCircle } from 'react-icons/ai';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  TimeScale,
  ChartOptions,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import * as DateFNS from 'date-fns';
import { easeQuadOut } from 'd3-ease';
import cn from 'classnames';
import DatePicker from 'react-datepicker';

import CircularProgressBar from '../../components/CircularProgressBar/CircularProgressBar';
import { useLoadingBars } from '../../components/LoadingBar/LoadingBar';
import { BarTimeline } from '../../components/Chart/Chart';
// import ChartLabelTooltip from '../../components/ChartLabelTooltip/ChartLabelTooltip';

import {
  ChartDataObject,
  CommunityProps,
  getChartListsAndSufficiency,
  NavbarComponent,
  PRICE_TYPE,
} from './Community.helper';

import { getCommunityInfo } from '../../apis/communities';
import {
  TimeRange,
  getStreamsAggregated,
  isGroupedByDevice,
} from '../../apis/streams';
import {
  getDate,
  getStringByLanguage,
  numUtils,
  prepareLabels,
  value2FlexibleUnit,
  value2KWH,
} from '../../utils/utils';
import { useAppDispatch, useAppSelector } from '../../services/hooks';
import { getColors } from '../../services/reducers/sharedReducer';
import {
  getSelectedDetails,
  setSelectedDetails,
} from '../../services/reducers/communityReducer';
import {
  getSelectedDate,
  getSelectedRange,
  setSelectedDate,
  setSelectedRange,
} from '../../services/reducers/sharedReducer';

import { ReactComponent as GreenGeneratorSVG } from '../../assets/images/green-generator.svg';
import { ReactComponent as HouseSVG } from '../../assets/images/house.svg';
import ArrowGif from '../../assets/images/arrow.gif';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-circular-progressbar/dist/styles.css';
import styles from './styles.module.scss';
import { addUserPageLog } from '../../apis/users';

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

ChartJS.defaults.borderColor = 'transparent';
ChartJS.defaults.elements.bar.borderRadius = 50;
ChartJS.defaults.elements.bar.borderSkipped = false;

const Community: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [showLoadingBar, hideLoadingBar] = useLoadingBars();

  const [xAxisLabels, setXAxisLabels] = useState<number[]>([]);

  const dispatch = useAppDispatch();
  const selectedDetails = useAppSelector(getSelectedDetails);
  const selectedRange = useAppSelector(getSelectedRange);
  const date = useAppSelector(getSelectedDate);
  const selectedDate = new Date(date);
  const [period, setPeriod] = useState<{
    startDate: Date;
    endDate: Date;
  } | null>(null);
  const [chartData, setChartData] = useState<ChartData<
    'bar',
    ChartDataObject[],
    number
  > | null>(null);

  const [prices, setPrices] = useState({
    community: 0.25,
    gain: 0.09,
  });
  const [communityInfo, setCommunityInfo] = useState<CommunityProps>({
    chartLists: {
      unit: getStringByLanguage('KWH'),
      Production: { Total: [], Shared: [], Individual: [] },
      Consumption: { Total: [], Shared: [], Individual: [] },
    },
    sufficiency: {
      total: 0,
      community: 0,
      unit: getStringByLanguage('KWH'),
    },
    producers: {
      value: 0,
      count: 0,
      unit: getStringByLanguage('KWH'),
    },
    consumers: {
      value: 0,
      count: 0,
      unit: getStringByLanguage('KWH'),
    },
    gains: 0,
  });

  const [chartOptions, setChartOptions] = useState<ChartOptions<'bar'>>();

  // const [tooltipRoot, setTooltipRoot] = useState<Root>();

  const colors = useAppSelector(getColors);

  ChartJS.defaults.color = `rgb(${colors.textColor})`;
  ChartJS.defaults.borderColor = `rgb(${colors.textColor})`;

  const init = async () => {
    const {
      data: { data },
    } = await getCommunityInfo();
    if (!data) {
      return;
    }

    setPrices({
      community: numUtils.decimal(data.community_price, 2),
      gain: numUtils.decimal(data.supplier_price - data.community_price, 2),
    });
  };

  useEffect(() => {
    init();
    addUserPageLog('Community');
  }, []);

  useEffect(() => {
    // let currentRoot = tooltipRoot;
    // if (!currentRoot) {
    //   const tooltipContainer = document.getElementById('kut-chart-tooltip');
    //   if (!tooltipContainer) {
    //     return;
    //   }
    //   const _tooltipRoot = createRoot(tooltipContainer);
    //   currentRoot = _tooltipRoot;
    //   setTooltipRoot(_tooltipRoot);
    // }

    setChartOptions({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
          labels: {
            boxWidth: 10,
            boxHeight: 10,
            useBorderRadius: true,
            borderRadius: 5,
            textAlign: 'right',
          },
          // onHover: (e, legendItem, legend) => {
          //   if (!currentRoot) {
          //     return;
          //   }
          //   console.log(e);
          //   console.log(legendItem);
          //   console.log(legend);
          //   currentRoot.render(
          //     <ChartLabelTooltip
          //       e={e}
          //       legendItem={legendItem}
          //       legend={legend}
          //       tooltipComponents={[]}
          //     />,
          //   );
          // },
        },
        title: {
          display: true,
          text:
            getStringByLanguage('COMMUNITY_ENERGY_USAGE') +
            ' (' +
            communityInfo.chartLists.unit +
            ')',
        },
      },
      scales: {
        x: {
          type: 'time',
          grid: {
            display: false,
          },
          time: {
            unit: 'hour',
            displayFormats: {
              hour: 'H:mm',
            },
          },
          ticks: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            callback: (value, index) => {
              switch (selectedRange) {
                case TimeRange.Today:
                  if (index % 4 === 0) {
                    return DateFNS.format(
                      new Date(value),
                      index === 0 ? 'H:mm' : 'k:mm',
                    );
                  }
                  return '';
                case TimeRange.Week:
                  return DateFNS.format(new Date(value), 'E');
                case TimeRange.Month:
                  return DateFNS.format(new Date(value), 'd');
                case TimeRange.Year:
                  return DateFNS.format(new Date(value), 'LLL');
                default:
                  return '';
              }
            },
            sampleSize: 1,
            autoSkip: false,
            source: 'labels',
          },
          stacked: true,
          alignToPixels: true,
        },
        y: {
          grid: {
            drawTicks: false,
          },
          ticks: {
            count: 4,
            padding: 10,
          },
          border: {
            display: false,
          },
        },
      },
    });
  }, [chartData]);

  useEffect(() => {
    const { chartLists } = communityInfo;
    const chart: ChartData<'bar', ChartDataObject[], number> = {
      labels: xAxisLabels,
      datasets: [
        {
          label: getStringByLanguage('CONSUMPTION'),
          data: chartLists.Consumption.Total,
          backgroundColor: '#CFCFCF',
          order: 3,
          maxBarThickness: 12,
          parsing: {
            xAxisKey: 'timestamp',
            yAxisKey: 'value',
          },
        },
        {
          label: getStringByLanguage('SHAREABLE_PRODUCTION'),
          data: chartLists.Production.Total,
          backgroundColor: '#FF8C00',
          order: 2,
          maxBarThickness: 12,
          parsing: {
            xAxisKey: 'timestamp',
            yAxisKey: 'value',
          },
        },
        {
          label: getStringByLanguage(['COLLECTIVE_SELF_CONSUMPTION']),
          data: chartLists.Consumption.Shared,
          backgroundColor: '#76C80F',
          order: 1,
          maxBarThickness: 12,
          parsing: {
            xAxisKey: 'timestamp',
            yAxisKey: 'value',
          },
        },
      ],
    };

    setChartData(chart);
  }, [communityInfo]);

  useEffect(() => {
    if (!period) {
      return;
    }

    const xAxis = prepareLabels(selectedRange, selectedDate);
    setXAxisLabels(xAxis);

    showLoadingBar();

    getStreamsAggregated({
      interval:
        selectedRange === TimeRange.Year
          ? '1M'
          : selectedRange === TimeRange.Today
          ? '15m'
          : '1d',
      groupBy: 'device',
      current_page: 'My Community',
      ...period,
    })
      .then(async ({ data: { data: streamData } }) => {
        if (!streamData) {
          throw new Error(getStringByLanguage('REQUEST_ERROR'));
        }

        const [streams] = streamData;

        if (isGroupedByDevice(streams)) {
          const { chartLists, sufficiency, producers, consumers } =
            getChartListsAndSufficiency(streams);

          sufficiency.community = numUtils.decimal(sufficiency.community);
          sufficiency.total = numUtils.decimal(sufficiency.total);

          const gains = numUtils.decimal(
            sufficiency.community * prices.gain,
            2,
          );

          const minValue = Math.min(sufficiency.community, sufficiency.total);

          if (minValue >= 1000) {
            sufficiency.community = value2FlexibleUnit({
              value: sufficiency.community,
              unit: sufficiency.unit,
            }).value;

            const { value, unit } = value2FlexibleUnit({
              value: sufficiency.total,
              unit: sufficiency.unit,
            });
            sufficiency.total = value;
            sufficiency.unit = unit;
          }

          setCommunityInfo({
            chartLists,
            sufficiency,
            producers,
            consumers,
            gains: gains,
          });
        }

        hideLoadingBar();
      })
      .catch(() => {
        hideLoadingBar();
      });
  }, [period]);

  useEffect(() => {
    setPeriodRange(selectedRange, selectedDate);
  }, [selectedRange, date]);

  const setPeriodRange = (selectedRange: TimeRange, current = new Date()) => {
    switch (selectedRange) {
      case TimeRange.Today:
        setPeriod({
          startDate: DateFNS.startOfDay(current),
          endDate: DateFNS.endOfDay(current),
        });
        break;
      case TimeRange.Week:
        setPeriod({
          startDate: DateFNS.startOfWeek(current),
          endDate: DateFNS.endOfWeek(current),
        });
        break;
      case TimeRange.Month:
        setPeriod({
          startDate: DateFNS.startOfMonth(current),
          endDate: DateFNS.endOfMonth(current),
        });
        break;
      case TimeRange.Year:
        setPeriod({
          startDate: DateFNS.startOfYear(current),
          endDate: DateFNS.endOfYear(current),
        });
        break;
      default:
        break;
    }
  };

  const { total, unit } = communityInfo.sufficiency;
  const community = communityInfo.chartLists.Consumption.Shared.reduce(
    (sum, item) => sum + item.value,
    0,
  );
  const community_unit = communityInfo.chartLists.unit;
  const fixedCommunity = value2FlexibleUnit({
    value: community,
    unit: community_unit,
  });
  const fixedTotal = value2FlexibleUnit({ value: total, unit });
  const community_kwh = value2KWH({ value: community, unit: community_unit });
  const total_kwh = value2KWH({ value: total, unit });
  const percent = Math.round((community_kwh.value * 100) / total_kwh.value);

  return (
    <div className={styles['kut-component-body']}>
      <div className={styles['kut-component-content']}>
        <NavbarComponent
          className={styles['community-navbar']}
          options={[
            {
              value: TimeRange.Today,
              label: getStringByLanguage('DAY'),
            },
            {
              value: TimeRange.Week,
              label: getStringByLanguage('WEEK'),
            },
            {
              value: TimeRange.Month,
              label: getStringByLanguage('MONTH'),
            },
            {
              value: TimeRange.Year,
              label: getStringByLanguage('YEAR'),
            },
          ]}
          useArrow={{
            onPrev: (value: TimeRange) => {
              if (!period) {
                return;
              }
              if (selectedRange !== TimeRange.Custom) {
                return dispatch(
                  setSelectedDate(getDate(value, selectedDate, -1).getTime()),
                );
              }
              setPeriod({
                startDate: getDate(value, period.startDate, -1),
                endDate: period.endDate,
              });
            },
            onNext: (value: TimeRange) => {
              if (!period) {
                return;
              }
              if (selectedRange !== TimeRange.Custom) {
                return dispatch(
                  setSelectedDate(getDate(value, selectedDate, 1).getTime()),
                );
              }
              setPeriod({
                startDate: period.startDate,
                endDate: getDate(value, period.endDate, 1),
              });
            },
            component: (
              <div className={cn([styles['inside-navbar']])}>
                <DatePicker
                  selected={period?.startDate}
                  dateFormat={'dd/MM/yyyy'}
                  onChange={(date) =>
                    date &&
                    period &&
                    (selectedRange !== TimeRange.Custom
                      ? dispatch(setSelectedDate(date.getTime()))
                      : setPeriod({
                          startDate: date,
                          endDate: period.endDate,
                        }))
                  }
                />
                <span> - </span>
                <DatePicker
                  selected={period?.endDate}
                  dateFormat={'dd/MM/yyyy'}
                  onChange={(date) =>
                    date &&
                    period &&
                    (selectedRange !== TimeRange.Custom
                      ? dispatch(setSelectedDate(date.getTime()))
                      : setPeriod({
                          startDate: period.startDate,
                          endDate: date,
                        }))
                  }
                />
              </div>
            ),
          }}
          onChange={(value: TimeRange) => {
            dispatch(setSelectedRange(value));
          }}
          value={selectedRange}
        />
        <div className={styles['community-detailed-info']}>
          <div className={styles['community-self-sufficiency']}>
            <div className={styles['community-self-sufficiency-header']}>
              <p>{getStringByLanguage('COMMUNITY_SELF_SUFFICIENCY')}</p>
              <BsInfoCircle
                className={cn(['kut-tooltip-item'])}
                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                  <ul className={styles['community-tooltip-item']}>
                    <li>
                      {getStringByLanguage('THE__')}
                      <b>{getStringByLanguage('SELF_CONSUMPTION')}</b>&nbsp;
                      {getStringByLanguage(
                        'IS_THE_PART_OF_THE_CONSUMPTION_THAT_COMES_FROM_THE_RENEWABLE_ENERGY_PRODUCTION_OF_THE_COMMUNITY',
                      )}
                      &nbsp;
                      {(fixedCommunity.value !== 0 ||
                        fixedTotal.value !== 0) && (
                        <>
                          {getStringByLanguage('IN_THIS_CASE_IT_IS_EQUAL_TO')}
                          &nbsp;
                          <b>
                            {fixedCommunity.value}
                            {fixedCommunity.unit}
                          </b>
                        </>
                      )}
                    </li>
                    <li>
                      {getStringByLanguage('THE__')}
                      <b>{getStringByLanguage('SELF_SUFFICIENCY')}</b>&nbsp;
                      {getStringByLanguage(
                        'IS_THE_SELF_CONSUMPTION_DIVIDED_BY_THE_TOTAL_CONSUMPTION',
                      )}
                      &nbsp;
                      {(fixedCommunity.value !== 0 ||
                        fixedTotal.value !== 0) && (
                        <>
                          {getStringByLanguage('IN_THIS_CASE_IT_IS_EQUAL_TO')}
                          &nbsp;
                          <b>
                            {fixedCommunity.value}
                            {fixedCommunity.unit}
                          </b>
                          &nbsp; /&nbsp;
                          <b>
                            {fixedTotal.value}
                            {fixedTotal.unit}
                          </b>
                          &nbsp; = <b>{percent}%</b>.&nbsp;
                          {getStringByLanguage('THIS_MEANS_THAT')}&nbsp;
                          <b>{percent}%</b>&nbsp;
                          {getStringByLanguage(
                            'OF_THE_COMMUNITY_CONSUMPTION_COMES_FROM_THE_RENEWABLE_ENERGY_PRODUCTION_OF_THE_COMMUNITY',
                          )}
                        </>
                      )}
                    </li>
                  </ul>,
                )}
              />
            </div>
            <div className={styles['community-self-sufficiency-content']}>
              <div className={styles['community-circular-progressbar']}>
                <CircularProgressBar
                  value={community_kwh.value}
                  maxValue={total_kwh.value}
                  duration={1}
                  easingFunction={easeQuadOut}
                >
                  {(value) => {
                    const { unit } = communityInfo.chartLists;
                    const fixedValue = value2FlexibleUnit({ value, unit });
                    return (
                      <div className={styles['community-circular-content']}>
                        <p
                          className={
                            styles['community-circular-content-consumption']
                          }
                        >
                          {getStringByLanguage('CONSUMPTION')}
                        </p>
                        <p
                          className={styles['community-circular-content-value']}
                        >
                          {fixedValue.value}
                        </p>
                        <p
                          className={styles['community-circular-content-unit']}
                        >
                          {unit}
                        </p>
                      </div>
                    );
                  }}
                </CircularProgressBar>
              </div>
              <div className={styles['community-circular-mark']}>
                <p>{getStringByLanguage('FROM_GRID')}</p>
                <p>{getStringByLanguage('FROM_COMMUNITY')}</p>
              </div>
            </div>
          </div>
          <div className={styles['community-details']}>
            <div className={styles['community-price']}>
              <div className={styles['community-price-header']}>
                <div>
                  <p
                    className={
                      selectedDetails === PRICE_TYPE.DETAILS_PRICE
                        ? styles['active']
                        : ''
                    }
                    onClick={() => {
                      dispatch(setSelectedDetails(PRICE_TYPE.DETAILS_PRICE));
                    }}
                  >
                    {getStringByLanguage('COMMUNITY_PRICE')}
                  </p>
                  <p
                    className={
                      selectedDetails === PRICE_TYPE.DETAILS_GAINS
                        ? styles['active']
                        : ''
                    }
                    onClick={() => {
                      dispatch(setSelectedDetails(PRICE_TYPE.DETAILS_GAINS));
                    }}
                  >
                    {getStringByLanguage('SAVINGS')}
                  </p>
                </div>
                <BsInfoCircle
                  className={cn(['kut-tooltip-item'])}
                  data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                    <div className={styles['community-tooltip-item']}>
                      {getStringByLanguage('THE_SAVINGS_ARE_THE_AGGREGATED')}
                      &nbsp;
                      <b>{getStringByLanguage('BILL_SAVINGS')}</b>&nbsp;
                      {getStringByLanguage([
                        'OF_THE_COMMUNITY_MEMBERS_DUE_TO_THE_CONSUMPTION_OF_THE_RENEWABLE_ENERGY_OF_THE_COMMUNITY',
                        'THESE_SAVINGS_ARE_HOWEVER_AN_APPROXIMATION_TAKEN_WITH_HYPOTHESES_AND_ARE_NOT_THE_REAL_ONE',
                      ])}
                    </div>,
                  )}
                />
              </div>
              <div className={styles['community-price-content']}>
                <AiOutlineEuroCircle />
                {selectedDetails === PRICE_TYPE.DETAILS_PRICE && (
                  <div>
                    <p>{prices.community}</p>
                    <span>{` / ${getStringByLanguage('KWH')}`}</span>
                  </div>
                )}
                {!(selectedDetails === PRICE_TYPE.DETAILS_PRICE) && (
                  <div>
                    <p>{communityInfo.gains}</p>
                  </div>
                )}
              </div>
            </div>
            <div className={styles['community-producers-consumers']}>
              <div className={styles['community-producers']}>
                <div className={styles['community-producers-header']}>
                  <p>{getStringByLanguage('PRODUCERS')}</p>
                </div>
                <div className={styles['community-producers-content']}>
                  <div className={styles['community-producers-generators']}>
                    <GreenGeneratorSVG
                      className={styles['community-generator-svg']}
                    />
                    <span>{communityInfo.producers.count}</span>
                  </div>
                  <p className={styles['community-producers-value']}>
                    {communityInfo.producers.value}&nbsp;
                    {communityInfo.producers.unit}
                  </p>
                </div>
              </div>
              <div className={styles['community-consumers']}>
                <div className={styles['community-consumers-header']}>
                  <p>{getStringByLanguage('CONSUMERS')}</p>
                </div>
                <div className={styles['community-consumers-content']}>
                  <div className={styles['community-consumers-house']}>
                    <HouseSVG className={styles['community-house-svg']} />
                    <span>{communityInfo.consumers.count}</span>
                  </div>
                  <p className={styles['community-consumers-value']}>
                    {communityInfo.consumers.value}&nbsp;
                    {communityInfo.consumers.unit}
                  </p>
                </div>
              </div>
              <div className={styles['community-circle']}>
                <div className={styles['community-circle-outside']}></div>
                <div className={styles['community-circle-inner']}></div>
                <img
                  src={ArrowGif}
                  alt="arrow"
                  className={styles['community-arrow-svg']}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles['community-chart']}>
          <div className={styles['community-chart-header']}>
            <BsInfoCircle
              className={cn(['kut-tooltip-item'])}
              data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                <ul className={styles['community-tooltip-item']}>
                  <li>
                    {getStringByLanguage('THE__')}
                    <b>{getStringByLanguage('COLLECTIVE_SELF_CONSUMPTION')}</b>
                    &nbsp;
                    {getStringByLanguage(
                      'IS_THE_PART_OF_THE_SHAREABLE_PRODUCTION_THAT_HAS_BEEN_SHARED_AMONG_THE_MEMBERS_AND_SELF_CONSUMED',
                    )}
                  </li>
                  <li>
                    {getStringByLanguage('THE__')}
                    <b>{getStringByLanguage('SHAREABLE_PRODUCTION')}</b>&nbsp;
                    {getStringByLanguage([
                      'IS_THE_PART_OF_THE_ENERGY_PRODUCTION_OF_PRODUCERS_THAT_IS_AVAILABLE_FOR_THE_CUSTOMERS',
                      'IT_IS_ALSO_CALLED_INJECTION',
                    ])}
                  </li>
                  <li>
                    {getStringByLanguage('THE__')}
                    <b>{getStringByLanguage('CONSUMPTION')}</b>&nbsp;
                    {getStringByLanguage([
                      'IS_THE_AMOUNT_OF_ENERGY_THAT_MEMBERS_TAKE_FROM_THE_GRID',
                      'IT_IS_THE_OFFTAKE_CONSUMPTION',
                    ])}
                  </li>
                </ul>,
              )}
            />
          </div>
          {chartData &&
            (selectedRange === TimeRange.Today &&
            DateFNS.isToday(selectedDate) ? (
              <BarTimeline options={chartOptions} data={chartData} />
            ) : (
              <Bar options={chartOptions} data={chartData} />
            ))}
          {/* <div id="kut-chart-tooltip" /> */}
        </div>
      </div>
    </div>
  );
};

export default Community;

import { FC } from 'react';
import styles from './styles.module.scss';
import arrowSvg from '../../assets/images/arrow2.svg';
import alarmSvg from '../../assets/images/alarm.svg';
import avatarSvg from '../../assets/images/user_avatar.png';
import transhSvg from '../../assets/images/trash.svg';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { formatDate } from '../../utils/utils';
import NotificationItem from '../NotificationItem/NotificationItem';
import Button, { BUTTON_TYPE } from '../Button/Button';
import { getStringByLanguage } from '../../utils/utils';
import { NOTIFICATION_LIMIT } from '../../utils/constants';

import cn from 'classnames';
import { NotificationInfo } from '../../apis/notifications';
interface propsType {
  show: boolean;
  setShow: (value: boolean) => void;
  data: NotificationInfo[];
}

const ActionCenter: FC<propsType> = ({ show, setShow, data }) => {
  return (
    <div
      className={cn([
        styles['action-center-body'],
        show ? styles['action-center-show'] : styles['action-center-hidden'],
      ])}
    >
      <div
        className={styles['action-center-arrow']}
        onClick={() => setShow(false)}
      >
        <img src={arrowSvg} alt="arrow image" />
      </div>
      <div className={styles['action-center-header']}>
        <img src={alarmSvg} className={styles['action-center-alarm']} />
        <div className={styles['action-center-user']}>
          <div className={styles['action-center-header-title']}>
            <p className={styles['action-center-header-name']}>
              Alexander Legrande
            </p>
            <p className={styles['action-center-header-role']}>
              Community manager
            </p>
          </div>
          <div className={styles['action-center-user-wrapper']}>
            <img
              alt="user-avatar"
              className={cn([styles['user-info-avatar']])}
              src={avatarSvg}
              width={62.36}
              height={62.36}
            />
            <span className={cn([styles['is-online-flag-section']])}></span>
          </div>
          <div className={cn([styles['dropdown-button-wrapper']])}>
            <BsChevronUp />
            <BsChevronDown />
          </div>
        </div>
      </div>
      <div className={styles['action-center-content']}>
        <div className={styles['today-date']}>
          Today, {formatDate(new Date(data[0]?.notification_end_at))}
        </div>
        {data.slice(0, NOTIFICATION_LIMIT).map((item) => (
          <NotificationItem key={item.id} data={item} />
        ))}
        {data.length > NOTIFICATION_LIMIT && (
          <div className={styles['action-center-content-button']}>
            <Button
              className={cn([styles['action-center-button']])}
              type={BUTTON_TYPE.DEFAULT}
            >
              {getStringByLanguage('LOAD_OLDER_NOTIFICATIONS')}
            </Button>
          </div>
        )}
      </div>
      <div className={styles['action-center-clear']}>
        <Button
          className={cn([styles['action-center-clear-button']])}
          type={BUTTON_TYPE.DANGER}
        >
          <img src={transhSvg} className={styles['trash-image']} />
          {getStringByLanguage('CLEAR_NOTIFICATIONS')}
        </Button>
      </div>
    </div>
  );
};

export default ActionCenter;
